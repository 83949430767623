import axios from "axios";

//创建实例
const request = axios.create({
  baseURL: process.env.VUE_APP_API1,
  timeout: 30000,
  headers: {

  },
});

//请求拦截器
request.interceptors.request.use((config) => {
  return config;
});

//响应拦截器
request.interceptors.response.use((response) => {
  if (response.data.code === 1) {
    return response.data.data;
  } else {
    return Promise.reject(response.data);
  }
},
  (error) => {
    return Promise.reject(error);
  }
);

export default request;

