<template>
  <div class="bottom-container">
    <div class="line-style">&nbsp;</div>
    <div class="bottom-padding-style">
      <!-- 红线 -->
      <div class="redLine"></div>
      <!-- 机构合作 -->
      <div class="bottom-top-other">
        <!-- 左（图标） -->
        <div class="logo-bottom-all">
          <div class="bottom-logo">
            <img
              src="../../assets/banner/logo-home.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>
          <div style="margin-top: 10px">
            <ul class="text-list">
              <li>
                <span class="name-text">地&nbsp;&nbsp;址：</span
                >北京市朝阳区广渠路42号院1号楼
              </li>
              <li>
                <span class="name-text">邮&nbsp;&nbsp;编：</span
                ><span>100022</span
                ><span class="name-text"
                  >&nbsp;&nbsp;&nbsp;&nbsp;传&nbsp;&nbsp;真：</span
                >
                <span>010-8771 9675</span>
              </li>
              <li>
                <span class="name-text">邮&nbsp;&nbsp;箱：</span
                >zjh@china-wdf.org.cn
              </li>
            </ul>
          </div>
        </div>
        <!-- 中 （跳转）-->
        <div class="link-container-all" style="cursor: pointer">
          <ul class="link-box">
            <li class="link-box-item" v-for="item in navigatorTitleList" :key="item.id" @click="$router.push({path:item.path,query:{nav_id:item.id}})">
              {{ item.title }}
            </li>
          </ul>
        </div>
        <!-- 右 （二维码）-->
        <div class="code-container">
          <div
            style="display: flex; flex-direction: column; align-items: center;row-gap: 10px;"
          >
            <div class="code-box">
              <img
                src="../../assets/icon/home_GY (1).png"
                alt=""
              />
            </div>
            <span style="color: #4b4744; ">官方微信公众号</span>
          </div>
          <div
            style="display: flex; flex-direction: column; align-items: center;row-gap: 10px;"
          >
            <div class="code-box">
              <img
                src="../../assets/icon/donationQ.png"
                alt=""
              />
            </div>
            <span style="color: #4b4744; ">捐赠二维码</span>
          </div>
        </div>
      </div>
      <!-- 底部备案 -->
      <div class="botoom-copy">
        <!-- 联系我们 -->
        <div>
          <ul class="flex-style">
            <li class="color-items-text">发文排行</li>
            <span>&nbsp;|&nbsp;</span>
            <li class="color-items-text">网站声明</li>
            <span>&nbsp;|&nbsp;</span>
            <li class="color-items-text">网站找错</li>
            <span>&nbsp;|&nbsp;</span>
            <li class="color-items-text">联系我们</li>
            <span>&nbsp;|&nbsp;</span>
            <li class="color-items-text-img">
              <img
                src="../../assets/icon/nav-icon9.png"
                alt=""
              />
              <img
                src="../../assets/icon/nav-icon10.png"
                alt=""
              />
            </li>
          </ul>
        </div>
        <!-- 版权 -->
        <div class="flex-style color-items-text content-middle">
          <p class="color-items-text" style="margin-right: 40px">
            版权所有：{{ systemConfigData.site_copyright }}
          </p>
          <p class="color-items-text">网站管理：{{  systemConfigData.site_admin}}</p>
        </div>
        <!-- 备案 -->
        <div>
          <div class="box-desc-record">
            <img
              src="../../assets/icon/gongan.png"
              alt=""
            />
            <p style="color: #a5a4a2">
              {{ systemConfigData.site_beian }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {reqHomeColumnTitle} from '@/api/home'
import {reqSystemConfig} from '@/api/bottom'
export default {
  data() {
    return {
      navigatorTitleList:[],
      systemConfigData:{}
    };
  },
  mounted() {
    this.getHomeColumnTitle()
    this.getSystemConfig()
  },
  methods:{
    async getHomeColumnTitle(){
      try{
        const result = await reqHomeColumnTitle('262,278,278,288,268,284,269')
        this.navigatorTitleList = result
      }catch(err){
        console.log('err',err);
      }
    },

    /* 网站配置 */
    async getSystemConfig(){
      try{
        const resutl  = await reqSystemConfig()
        this.systemConfigData = resutl
      }catch(err){
        console.log('err',err);
        
      }
    }
  }
};
</script>

<style scoped lang="scss">
.bottom-container {
  // padding: 25px 0px 50px 0px;git
  background-color: #dcdcdc;
}
.bottom-logo {
  width: 511px;
  height: 65px;
}
.name-text {
  letter-spacing: 4px;
}
.text-list {
  color: #4b4744;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  li {
    line-height: 1.75;
  }
}
.link-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  // row-gap: 15px;
}
.link-container-all {
  display: flex;
  width: 18%;
  margin-left: 80px;
  height: 100%;
  margin-top: -30px;
}
.logo-bottom-all {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.link-box-item {
  line-height: 1.5;
  margin: 4px 20px;
  color: #9b3c26;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 19px;
}
.flex-style {
  display: flex;
  align-items: center;
  .color-items-text-img{
  img{
    width: 30px;
    height: 30px;
  }
  }
}
.code-container {
  display: flex;
  align-items: center;
  margin-left: 170px;
  column-gap: 50px;
}
.bottom-top-other {
  display: flex;
  align-items: center;
  // justify-content: space-around;
  margin-top: 30px;
  margin-left: 50px;
}
.code-box {
  width: 140px;
  height: 140px;
  // padding: 6px;
  // margin: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #fff;
  img{
    width: 140px;
    height: 140px;
  }
}

.color-items-text {
  color: #4b4744;
}
.box-desc-record {
  display: flex;
  align-items: center;
  img{
    width: 18px;
    height: 18px;
  }
}
.botoom-copy {
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 1px;
position: relative;
top: -30px;
  
}
.content-middle {
  margin: 10px 0;
}
.line-style {
  position: relative;
  width: 100vw;
  height: 3px;
  top: 15px;
  background-color: #c0192b;
}
.bottom-padding-style {
  padding: 0 10%;
  height: 240px;
}

// @media screen and (max-width: 1450px) {
//   .bottom-padding-style {
//     padding: 0 3%;
//   }
//   .link-box-item {
//     margin: 8px 12px !important;
//   }
// }
// /* 大屏幕  大于1200px*/
// @media screen and (max-width: 1200px) {
//   .bottom-padding-style {
//     padding: 0;
//   }
//   .link-box-item {
//     margin: 8px 22px !important;
//   }
//   .code-container {
//     display: flex;
//     flex-direction: column;
//     margin-left: auto;
//   }
// }
// /* 中型设备（台式电脑，1200px 以下） */
// @media screen and (max-width: 992px) {
//   .bottom-top-other {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
//   .link-container-all {
//     display: flex;
//     margin: 10px;
//   }
//   .code-container {
//     display: flex !important;
//     flex-direction: row !important;
//     margin: 0 !important;
//   }
// }
// /* 小型设备（平板电脑，992px 以下） */
// @media screen and (max-width: 768px) {
// }
</style>
