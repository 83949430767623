import Vue from 'vue'
import VueRouter from 'vue-router'
import 'nprogress/nprogress.css'
import nprogress from 'nprogress'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  /*首页*/
  {
    path: '/home',
    name: 'home',
    meta: {
      arr: [
        { text: "关于我们", linto: "/about",nav_id:255 },
        { text: "新闻动态", linto: "/news" ,nav_id:256},
        { text: "公益项目", linto: "/object",nav_id:257 },
        { text: "信息公开", linto: "/informations",nav_id:258 },
        { text: "合作伙伴", linto: "/cooperate",nav_id:259 },
        { text: "志愿服务", linto: "/voluntary",nav_id:307 },
        { text: "我要捐赠", linto: "/donation",nav_id:261 },
      ],
      isShowTab:true
    },
    component: () => import('../views/Home/home.vue')
  },

  /* 搜索 */
  {
    path: '/home/search',
    name: 'search',
    meta: {
      arr: ["搜索"],
      homeMenuIsShow: '/home'
    },
    component: () => import('../views/Search/search.vue')
  },
  /* 捐赠移动端 */
  {
      path: '/h5/donation',
      name: 'h5donation',
      meta: {
        arr: ["捐赠"],
        homeMenuIsShow: '/home',
        isShowHeaderTab:true
      },
      component: () => import('../views/H5donation/index.vue')
  },
  /* 捐赠动态 */
  {
    path: '/home/donateActive',
    name: 'donateActive',
    meta: {
      arr: ["捐赠动态"],
      homeMenuIsShow: '/home'
    },
    component: () => import('@/views/DonateActive/index.vue')
  },

  /* 公示公告 */
  {
    path: '/home/notice',
    name: 'notice',
    meta: {
      arr: ["公示公告"],
      homeMenuIsShow: '/home'
    },
    component: () => import('../views/Notice/notice.vue')
  },
  /*捐赠*/
  {
    path: '/home/firm',
    name: 'Donate',
    meta: {
      arr: ["个人捐赠"],
      homeMenuIsShow: '/home'
    },
    component: () => import('@/views/PersonDonat/index.vue')
  },
  /*服务阵地*/
  {
    path: '/home/servicebase',
    name: 'servicebase',
    meta: {
      arr: ["服务阵地"],
      homeMenuIsShow: '/home'
    },
    component: () => import('@/views/ServiceBase/service.vue')
  },
  {
    path: '/home/orderPay',
    name: 'Pay',
    meta: {
      arr: ["支付页面"],
      homeMenuIsShow: '/home'
    },
    component: () => import('@/views/Pay/index.vue')
  },


  /*关于我们*/
  {

    path: '/about',
    name: 'about',
    meta: {
      arr: ["关于我们"]
    },
    redirect: '/about/introduce',
    children: [
      {
        path: 'introduce',
        name: 'introduce',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262
            },
            {
              text: "机构介绍",
              linto: "/about/introduce",
              nav_id:262

            }
          ]
        },
        component: () => import('../views/About/introduce.vue')
      },
      {
        path: 'constitution',
        name: 'constitution',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "机构章程",
              linto: "/about/constitution",
              nav_id:263

            }
          ]
        },
        component: () => import('../views/About/constitution.vue')
      },
      {
        path: 'organization',
        name: 'organization',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "组织架构",
              linto: "/about/organization",
              nav_id:264

            }
          ]
        },
        component: () => import('../views/About/organization.vue')
      },
      {
        path: 'leader',
        name: 'leader',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "理事会",
              linto: "/about/leader",
              nav_id:275

            }
          ]
        },
        component: () => import('../views/About/leader.vue')
      },
      {
        path: 'monitor',
        name: 'monitor',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "监事会",
              linto: "/about/monitor",
              nav_id:265
            }
          ]
        },
        component: () => import('../views/About/monitor.vue')
      },
      {
        path: 'past',
        name: 'past',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262
            },
            {
              text: "历任领导",
              linto: "/about/past",
              nav_id:266
            }
          ]
        },
        component: () => import('../views/About/past.vue')
      },
      {
        path: 'history',
        name: 'history',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262


            },
            {
              text: "历史沿革",
              linto: "/about/history",
              nav_id:267

            }
          ]
        },
        component: () => import('../views/About/history.vue')
      },
      {
        path: 'donationstory',
        name: 'donationstory',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "捐赠故事",
              linto: "/about/donationstory",
              nav_id:268

            }
          ]
        },
        component: () => import('../views/About/donationStory.vue')
      },
      {
        path: 'charitable',
        name: 'charitable',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "慈善文化",
              linto: "/about/charitable",
              nav_id:269

            }
          ]
        },
        component: () => import('../views/About/charitable.vue')
      },
      {
        path: 'party',
        name: 'party',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "党建工作",
              linto: "/about/party",
              nav_id:270

            }
          ]
        },
        component: () => import('../views/About/party.vue')
      },
      {
        path: 'teamwork',
        name: 'teamwork',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "工作团队",
              linto: "/about/teamwork",
              nav_id:271
            }
          ]
        },
        component: () => import('../views/About/teamwork.vue')
      },
      {
        path: 'picture',
        name: 'picture',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262
            },
            {
              text: "图片集锦",
              linto: "/about/picture",
              nav_id:272

            }
          ]
        },
        component: () => import('../views/About/picture.vue')
      },
      {
        path: 'pennants',
        name: 'pennants',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "锦旗展",
              linto: "/about/pennants",
              nav_id:273

            }
          ]
        },
        component: () => import('../views/About/pennants.vue')
      },

      {
        path: 'contact',
        name: 'contact',
        meta: {
          arr: [
            {
              text: "关于我们",
              linto: "/about",
              nav_id:262

            },
            {
              text: "联系我们",
              linto: "/about/contact",
              nav_id:274

            }
          ]
        },
        component: () => import('../views/About/contact.vue')
      },




    ],
    component: () => import('../views/About/about.vue')
  },
  /*新闻动态*/
  {

    path: '/news',
    name: 'news',
    meta: {
      arr: ["新闻动态"]
    },
    redirect: '/news/reported',
    children: [
      {
        path: 'reported',
        name: 'news_reported',
        meta: {
          arr: [
            {
              text: "新闻动态",
              linto: "/news",
              nav_id:278
            },
            {
              text: "工会要闻",
              linto: "/news/reported",

              nav_id:278

            }
          ]
        },
        component: () => import('../views/News/reported.vue')
      },
      {
        path: 'politics',
        name: 'politics',
        meta: {
          arr: [
            {
              text: "新闻动态",
              linto: "/news",
              nav_id:278

            },
            {
              text: "时政新闻",
              linto: "/news/politics",
              nav_id:281

            }
          ]
        },
        component: () => import('../views/News/politics.vue')
      },
      {
        path: 'agencies',
        name: 'agencies',
        meta: {
          arr: [
            {
              text: "新闻动态",
              linto: "/news",
              nav_id:278

            },
            {
              text: "我会新闻",
              linto: "/news/agencies",
              nav_id:282

            }
          ]
        },
        component: () => import('../views/News/agencies.vue')
      },
      {
        path: 'video',
        name: 'video',
        meta: {
          arr: [
            {
              text: "新闻动态",
              linto: "/news",
            },
            {
              text: "视频资讯",
              linto: "/news/video",
            }
          ]
        },
        component: () => import('../views/News/video.vue')
      },
    ],
    component: () => import('../views/News/news.vue')
  },
  /*公益项目*/
  {

    path: '/object',
    name: 'object',
    meta: {
      arr: ["公益项目"]
    },
    redirect: '/object/objectdesc',
    children: [
      {
        path: 'objectdesc',
        name: 'objectdesc',
        meta: {
          arr: [
            {
              text: "公益项目",
              linto: "/object",
              nav_id:284

            },
            {
              text: "项目介绍",
              linto: "/object/objectdesc",
              nav_id:284

            }
          ]
        },
        component: () => import('../views/Object/objectDesc.vue')
      },
      {
        path: 'specialfunds',
        name: 'specialfunds',
        meta: {
          arr: [
            {
              text: "公益项目",
              linto: "/object",
              nav_id:284
            },
            {
              text: "专项基金",
              linto: "/object/specialfunds",
              nav_id:283
            }
          ]
        },
        component: () => import('../views/Object/specialFunds.vue')
      },
      {
        path: 'brandingprojects',
        name: 'brandingprojects',
        meta: {
          arr: [
            {
              text: "公益项目",
              linto: "/object",
              nav_id:284

            },
            {
              text: "品牌项目",
              linto: "/object/brandingprojects",
              nav_id:285

            }
          ]
        },
        component: () => import('../views/Object/brandingProjects.vue')
      },
      {
        path: 'charityactive',
        name: 'charityactive',
        meta: {
          arr: [
            {
              text: "公益项目",
              linto: "/object",
              nav_id:283

            },
            {
              text: "公益活动",
              linto: "/object/charityactive",
              nav_id:286

            }
          ]
        },
        component: () => import('../views/Object/charityActive.vue')
      },
      {
        path: 'provideactive',
        name: 'provideactive',
        meta: {
          arr: [
            {
              text: "公益项目",
              linto: "/object",
              nav_id:283

            },
            {
              text: "抗震救灾",
              linto: "/object/provideactive",
              nav_id:324

            }
          ]
        },
        component: () => import('../views/Object/provideActive.vue')
      },
      {
        path: 'objectactive',
        name: 'objectactive',
        meta: {
          arr: [
            {
              text: "公益项目",
              linto: "/object",
              nav_id:283

            },
            {
              text: "项目动态",
              linto: "/object/objectactive",
              nav_id:287

            }
          ]
        },
        component: () => import('../views/Object/objectActive.vue')
      },
    ],
    component: () => import('../views/Object/object.vue')
  },
  /*信息公开*/
  {

    path: '/informations',
    name: 'informations',
    meta: {
      arr: ["信息公开"]
    },
    redirect: '/informations/msgpublicly',
    children: [
      {
        path: 'msgpublicly',
        name: 'msgpublicly',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "政策法规",
              linto: "/informations/msgpublicly",
              nav_id:288

            }
          ]
        },
        component: () => import('../views/informations/msgPublicly.vue')
      },
      {
        path: 'regimepublicly',
        name: 'regimepublicly',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "制度公开",
              linto: "/informations/regimepublicly",
              nav_id:289

            }
          ]
        },
        component: () => import('../views/informations/regimePublicly.vue')
      },
      {
        path: 'report',
        name: 'report',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "机构报告",
              linto: "/informations/report",
              nav_id:290

            }
          ]
        },
        component: () => import('../views/informations/report.vue')
      },
      {
        path: 'annualreport',
        name: 'annualreport',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "年度报告",
              linto: "/informations/annualreport",
              nav_id:291

            }
          ]
        },
        component: () => import('../views/informations/annualReport.vue')
      },
      {
        path: 'financialreporting',
        name: 'financialreporting',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "财务报告",
              linto: "/informations/financialreporting",
              nav_id:292

            }
          ]
        },
        component: () => import('../views/informations/FinancialReporting.vue')
      },
      {
        path: 'auditreport',
        name: 'auditreport',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "审计报告",
              linto: "/informations/auditreport",
              nav_id:293

            }
          ]
        },
        component: () => import('../views/informations/AuditReport.vue')
      },
      {
        path: 'relevance',
        name: 'relevance',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "关联方",
              linto: "/informations/relevance",
              nav_id:294

            }
          ]
        },
        component: () => import('../views/informations/relevance.vue')
      },
      {
        path: 'topdonations',
        name: 'topdonations',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288
              
            },
            {
              text: "爱心榜单",
              linto: "/informations/topdonations",
              nav_id:295

            }
          ]
        },
        component: () => import('../views/informations/topDonations.vue')
      },
      {
        path: 'donationspublic',
        name: 'donationspublic',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "捐赠公开",
              linto: "/informations/donationspublic",
              nav_id:296

            }
          ]
        },
        component: () => import('../views/informations/donationsPublic.vue')
      },
      {
        path: 'charitymakers',
        name: 'charitymakers',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "公益榜样",
              linto: "/informations/charitymakers",
              nav_id:297

            }
          ]
        },
        component: () => import('../views/informations/charityMakers.vue')
      },
      {
        path: 'endorsers',
        name: 'endorsers',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "形象大使",
              linto: "/informations/endorsers",
              nav_id:299

            }
          ]
        },
        component: () => import('../views/informations/endorsers.vue')
      },
      {
        path: 'volunteers',
        name: 'volunteers',
        meta: {
          arr: [
            {
              text: "信息公开",
              linto: "/informations",
              nav_id:288

            },
            {
              text: "志愿先锋",
              linto: "/informations/volunteers",
              nav_id:298

            }
          ]
        },
        component: () => import('../views/informations/volunteers.vue')
      },
    ],
    component: () => import('../views/informations/informations.vue')
  },
  /*合作伙伴*/
  {

    path: '/cooperate',
    component: () => import('../views/Cooperate/cooperate.vue'),
    name: 'cooperate',
    redirect: '/cooperate/laborUnion',
    meta: {
      arr: ["合作伙伴"]
    },
    children: [
      /* 工会系统 */
      {
        path: 'laborUnion',
        name: 'laborUnion',
        component: () => import('@/views/Cooperate/components/LaborUnion.vue'),
        meta: {
          arr: [
            {
              text: "合作伙伴",
              linto: "/cooperate",
              nav_id:300

            },
            {
              text: "工会系统",
              linto: "/cooperate/laborUnion",
              nav_id:300

            }
          ]
        },
      },
      // 企业单位
      {
        path: 'firm',
        name: 'firm',
        component: () => import('@/views/Cooperate/components/Firm.vue'),
        meta: {
          arr: [
            {
              text: "合作伙伴",
              linto: "/cooperate",
              nav_id:300

            },
            {
              text: "企业单位",
              linto: "/cooperate/firm",
              nav_id:301

            }
          ]
        },
      },
      //媒体机构
      {
        path: 'media',
        name: 'media',
        component: () => import('@/views/Cooperate/components/Media.vue'),
        meta: {
          arr: [
            {
              text: "合作伙伴",
              linto: "/cooperate",
              nav_id:300

            },
            {
              text: "媒体机构",
              linto: "/cooperate/media",
              nav_id:302

            }
          ]
        },
      },
    ]
  },
  /*志愿服务*/
  {

    path: '/voluntary',
    name: 'voluntary',
    component: () => import('../views/Voluntary/voluntary.vue'),
    redirect: '/voluntary/draft',
    meta: {
      arr: ["志愿服务"]
    },
    children: [
      {
        path: 'draft',
        name: 'draft',
        component: () => import('@/views/Voluntary/components/Draft.vue'),
        meta: {
          arr: [
            {
              text: "志愿服务",
              linto: "/voluntary",
              nav_id:260



            },
            {
              text: "志愿者征召",
              linto: "/voluntary/draft",
              nav_id:260

            }
          ]
        },
      },
      {
        path: 'institutionDraft',
        name: 'institutionDraft',
        component: () => import('@/views/Voluntary/components/InstitutionDraft.vue'),
        meta: {
          arr: [
            {
              text: "志愿服务",
              linto: "/voluntary",
              nav_id:260

            },
            {
              text: "机构志愿者征召",
              linto: "/voluntary/institutionDraft",
              nav_id:308

            }
          ]
        },
      },
    ]
  },
  /*我要捐赠*/
  {

    path: '/donation',
    name: 'donation',
    component: () => import('../views/Donation/donation.vue'),
    redirect: '/donation/project',
    meta: {
      arr: ["我要捐赠"]
    },
    children: [
      {
        path: 'project',
        name: 'project',
        component: () => import('@/views/Donation/components/Project.vue'),
        meta: {
          arr: [
            {
              text: "我要捐赠",
              linto: "/donation",
              nav_id:275

            },
            {
              text: "项目捐赠",
              linto: "/donation/project",
              nav_id:275

            }
          ]
        },
      },

      {
        path: 'search',
        name: 'Search',
        component: () => import('@/views/Donation/components/Search.vue'),
        meta: {
          arr: [
            {
              text: "我要捐赠",
              linto: "/donation",
              nav_id:275

            },
            {
              text: "捐赠查询",
              linto: "/donation/search",
              nav_id:309

            }
          ]
        },
      },
      {
        path: 'credential',
        name: 'credential',
        component: () => import('@/views/Donation/components/Credential.vue'),
        meta: {
          arr: [
            {
              text: "我要捐赠",
              linto: "/donation",
              nav_id:275

            },
            {
              text: "捐赠证书",
              linto: "/donation/credential",
              nav_id:310

            }
          ] 
        },
      },

    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(opction) {
  //   return { x: 0, y: 0 }
  // }
})

/* 全局前置路由导航 */
router.beforeEach((to, from, next) => {
  nprogress.start()
  next()
})
/* 全局后置钩子 */
router.afterEach(() => {
  nprogress.done()
})

// router.onError((error) => {
//   const jsPattern = /Loading chunk (\S)+ failed/g
//   const cssPattern = /Loading CSS chunk (\S)+ failed/g
//   const isChunkLoadFailed = error.message.match(jsPattern || cssPattern)
//   const targetPath = router.history.pending.fullPath
//   if (isChunkLoadFailed) {
//     localStorage.setItem('targetPath', targetPath)
//     window.location.reload()
//   }
// })

// router.onReady(() => {
//   const targetPath = localStorage.getItem('targetPath')
//   const tryReload = localStorage.getItem('tryReload')
//   if (targetPath) {
//     localStorage.removeItem('targetPath')
//     if (!tryReload) {
//       router.replace(targetPath)
//       localStorage.setItem('tryReload', '/home')
//     } else {
//       localStorage.removeItem('tryReload')
//     }
//   }
// })
export default router
export { routes }
