export default (timestamp, status) => {
    if (timestamp === 0 || timestamp == null) {
        return "";
    } else {
        var date = new Date(timestamp * 1000)
        var Y = date.getFullYear() + '-'
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
        var D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' '
        var H = date.getHours() + ':'
        var M2 = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
        var S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
        if (status == 1) {
            return Y + M + D;
        } else if (status == 2) {
            return Y + M + D + H + M2 + S
        }

    }
}