<template>
  <div id="app">
    <Headers v-if="!$route.meta.isShowHeaderTab"></Headers>
    <router-view />
    <Bottoms v-if="!$route.meta.isShowHeaderTab"></Bottoms>
    <!-- <Headers></Headers>
    <router-view />
    <Bottoms></Bottoms> -->
    <div id="outer" v-if="$route.meta.isShowTab">
      <div class="box">
        <img src="./assets/icon/home_GY (1).png" alt="" />
        官方微信公众号
      </div>
      <div class="box">
        <img src="./assets/icon/home_GY (6).png" alt="" />
        捐赠二维码
      </div>
    </div>
  </div>
</template>
<script>
import Headers from "@/components/LayoutHeader/header.vue";
import Bottoms from "@/components/LayoutBottom/bottom.vue";
export default {
  components: {
    Headers,
    Bottoms,
  },
};
</script>
<style lang="scss" scoped>
.box {
  width: 140px;
  height: 190px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  row-gap: 20px;
  font-size: 12px;
  img {
    width: 105px;
  }
}

#outer {
  position: fixed;
  z-index: 1000;
  top: 30%;
  right: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
  background-color: #f1f1f1;
  box-shadow: 6px 6px 6px #f1f1f1;
}
</style>
