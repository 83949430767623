import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import axios from "axios"
import './styles/min.css'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import  timestampToTime from '@/utils/timestampToTime'
import '@/directive/vdown'
import 'swiper/css/swiper.min.css'

// 字体文件
import '@/assets/font/font.css';
Vue.prototype.$ajax = axios
import 'element-ui/lib/theme-chalk/index.css';
//自定义element主题颜色
import '../theme/index.css'
Vue.use(ElementUI);
Vue.filter('filter_timestampToTime',timestampToTime)
Vue.use(VueDOMPurifyHTML)
Vue.prototype.$bus = ''
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
Vue.config.productionTip = false

//百度统计代码开始
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', to.fullPath])
    }
  }
  next()
})
//百度统计代码结束
