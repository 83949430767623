<template>
  <div class="header_outer">
    <!-- <div class="header-home-title container">
      <div class="header-right-container">
        <div style="display: flex; align-items: flex-start">
          <div class="logo-image">
            <img
              src="../../assets/banner/logo-home.png"
              alt=""
              style="width: 100%; height: 100%"
            />
          </div>

          <div class="left-style" style="margin-top: 20px">
            <ul class="flex-style">
              <li class="top-text-style">微信公众号</li>
              <span class="top-text-style">&nbsp;|&nbsp;</span>
              <li class="top-text-style">小程序</li>
              <span class="top-text-style">&nbsp;|&nbsp;</span>
              <li class="top-text-style">微博</li>
              <span class="top-text-style">&nbsp;|&nbsp;</span>
              <li class="top-text-style">邮箱</li>
            </ul>
          </div>
        </div>
        <div class="left-style search-box">
          <div class="title-search-box">提升职工生活品质 助力共同富裕</div>
          <div class="desc-search-box" @click="toSearch()">
            <input
              type="text"
              placeholder="请输入关键词"
              style=""
              class="input-home-style"
            />
            <div class="search-icons-box">
              <img
                src="../../assets/icon/search.png"
                alt=""
                style="width: 18px; height: 18px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="header-title-nav">
      <div class="title-icon-nav" @click="showNav">
        <img
          src="../../assets/icon/up_down.png"
          alt=""
          style="width: 30px; height: 30px"
        />
      </div>
      <el-popover
        class="pop-style"
        placement="bottom"
        width="100"
        trigger="click"
        v-model="visible"
      >
        <div>
          <ul class="nav-left-pop">
            <li
              v-for="(item, index) in list"
              :key="index"
              class="nav-items-left-pop"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </el-popover>
      <div class="title-nav-items">
        <img src="../../assets/icon/nav-icon1.png" alt="" class="nav-icon" />
        <router-link to="/">首页</router-link>
      </div>
      <div class="title-nav-items">
        <img src="../../assets/icon/nav-icon2.png" alt="" class="nav-icon" />
        <router-link to="/about">关于我们</router-link>
      </div>
      <div class="title-nav-items">
        <img src="../../assets/icon/nav-icon3.png" alt="" class="nav-icon" />
        <router-link to="/news">新闻动态</router-link>
      </div>
      <div class="title-nav-items">
        <img src="../../assets/icon/nav-icon4.png" alt="" class="nav-icon" />
        <router-link to="/object">公益项目</router-link>
      </div>
      <div class="title-nav-items" @click="navigate('/informations')">
        <img src="../../assets/icon/nav-icon5.png" alt="" class="nav-icon" />
        信息公开
      </div>
      <div class="title-nav-items" @click="navigate('/cooperate')">
        <img src="../../assets/icon/nav-icon6.png" alt="" class="nav-icon" />
        合作伙伴
      </div>
      <div class="title-nav-items" @click="navigate('/voluntary')">
        <img src="../../assets/icon/nav-icon7.png" alt="" class="nav-icon" />
        志愿服务
      </div>
      <div class="title-nav-items" @click="navigate('/donation')">
        <img src="../../assets/icon/nav-icon8.png" alt="" class="nav-icon" />
        我要捐赠
      </div>
    </div> -->

    <!-- 头部 -->
    <header class="header">
      <!-- 左侧图片 -->
      <div>
        <img class="img" src="../../assets/banner/logo-home.png" alt="" />
      </div>
      <!-- 右侧搜索 -->
      <div class="right">
        <!-- 公众号 ,小程序 跳转链接 -->
        <div class="headerSkip">
          <el-popover placement="bottom-start" width="" trigger="hover">
            <img
              src="../../assets/icon/home_GY (1).png"
              alt=""
            />
            <span slot="reference" class="after">微信公众号</span>
          </el-popover>
          <el-popover placement="bottom-start" width="" trigger="hover">
            <img
              src="../../assets/icon/weixinXCX_20231020154731.png"
              alt=""
            />
            <span slot="reference" class="after">小程序</span>
          </el-popover>
          <el-popover placement="bottom-start" width="" trigger="hover">
            <div>
              <p>基金会邮箱</p>
              <p>zjh@china-wdf.org.cn</p>
            </div>
            <span slot="reference">邮箱</span>
          </el-popover>
        </div>
        <!-- 搜索框 -->
        <div class="headerSearch">
          <input class="searchIpt" placeholder="请输入关键词" type="text"  v-model="searchValue" @keyup.enter="navigateSearch()"/>
          <span class="serachImg" @click="navigateSearch()">
            <img src="../../assets/icon/search.png" alt="" />
          </span>
        </div>
      </div>
    </header>
    <!-- 导航 -->
    <div class="navigation">
      <ul class="navigation-box">
        <li
          @mouseleave="enterIndex = ''"
          @mouseenter="enterIndex = index"
          v-for="(value, index) in menuTitleList"
          :key="value.id"
        >
        <!-- 一级 -->
          <div
            @click="navigate(value,1)"
            :class="
              routePath === `/${value.path.split('/')[1]}` ||
              $route.meta.homeMenuIsShow === `/${value.path.split('/')[1]}`
                ? 'active'
                : ''
            "
          >
            <img :src="value.icon" alt="" />
            <nav>{{ value.title }}</nav>
          </div>
          <!-- 二级 -->
          <div class="hover-box" v-if="enterIndex === index">
            <ul class="box-container">
              <li
                class="box-items"
                :class="item.path === $route.path ? 'box-items-act' : ''"
                v-for="item in value.children"
                :key="item.id"
                @click="navigate(item,2)"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { reqMenuList } from "@/api/home";
export default {
  name: "LayoutHeader",
  data() {
    return {
      routePath: "",
      menuTitleList: [],
      enterIndex: "",
      searchValue:''
    };
  },

  watch: {
    $route: {
      handler(newVal) {
        const nowPath = newVal.path.split("/");
        this.routePath = "/" + nowPath[1];
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.getMenuList();
  },

  methods: {
    /* 路由跳转 */
    navigate(item,rank) {
      let path = "";
      let nav_id = "";
      if (item.children&&rank===1) {
        path = item.children[0].path;
        nav_id = item.children[0].nav_id;
      } else {
        path = item.path;
        nav_id = item.nav_id;
      } 
      if (!item.id) path = item
      
      this.$router.push({
        path,
        query: {
          nav_id,
        },
      });
    },
    navigateSearch() {
        this.$router.push({
          path:'/home/search',
          query: {
            search:this.searchValue,
          },
        });
      setTimeout(() => {
          this.searchValue = ''
        },500)
    },
    /* 请求导航栏数据 */
    async getMenuList() {
      try {
        const result = await reqMenuList();
        this.menuTitleList = result;
        // this.setMenuListHandler(result)
        this.$bus = result
      } catch (err) {
        console.log("err", err);
      }
    },
  },
};
</script>

<style scoped lang="scss">
// .input-home-style {
//   width: 200px;
//   outline: none;
//   border: 1px solid #c0192b;
//   height: 30px;
//   padding: 0 6px;
//   font-size: 12px;
// }
// .header-home-title {
//   display: flex;
//   flex-direction: column;
//   margin-top: 20px;
// }
// .container {
//   padding: 0 20%;
// }
// .logo-image {
//   /* margin-right: 18%; */
//   margin-top: 3%;
//   width: 60%;
// }
// .left-style {
//   margin-left: auto;
// }
// .header-right-container {
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
// .flex-style {
//   display: flex;
//   align-items: center;
// }
// .top-text-style {
//   color: #c0192b;
//   font-size: 12px;
// }
// .title-search-box {
//   letter-spacing: 1.5px;
//   color: #c0192b;
// }
// .desc-search-box {
//   display: flex;
//   align-items: center;
//   margin: 20px 0;
// }
// .search-box {
//   display: flex;
//   flex-direction: column;
//   margin-top: -20px;
// }
// .search-icons-box {
//   width: 40px;
//   height: 30px;
//   background-color: #c0192b;
//   border: 1px solid transparent;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// .header-title-nav {
//   width: 100%;
//   height: 40px;
//   background-color: #c0192b;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
// .title-nav-items {
//   padding: 10px 20px;
//   display: flex;
//   align-items: center;
//   color: #fff;
//   border-left: 1px solid #fff;
//   font-weight: 700;
//   cursor: pointer;
// }
// .nav-icon {
//   width: 20px;
//   height: 20px;
//   margin-right: 8px;
// }
// .title-nav-items:last-child {
//   border-right: 1px solid #fff;
// }
// .title-icon-nav {
//   display: none;
// }
// /* 大屏幕  大于1200px*/
// @media screen and (max-width: 1200px) {
//   .container {
//     padding: 0 5%;
//   }
//   .header-title-nav {
//     width: 100%;
//     height: 40px;
//     background-color: #c0192b;
//     padding: 0 5%;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }
// }
// /* 中型设备（台式电脑，1200px 以下） */
// @media screen and (max-width: 992px) {
//   .container {
//     padding: 0 1%;
//   }
//   .header-title-nav {
//     position: relative;
//     width: 100%;
//     height: 40px;
//     background-color: #c0192b;
//     padding: 0 1%;
//     display: flex;
//     align-items: center;
//     justify-content: flex-start;
//   }
//   .title-nav-items {
//     display: none;
//   }
//   .nav-items-left-pop {
//     line-height: 1.5;
//     margin: 6px 0;
//     cursor: pointer;
//   }
//   .nav-left-pop {
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//   }
//   .pop-style {
//     position: absolute;
//     top: 40px;
//     left: 8px;
//   }
//   .title-icon-nav {
//     display: block;
//   }
// }
// /* 小型设备（平板电脑，992px 以下） */
// @media screen and (max-width: 768px) {
// }

/* 头部内容 */
.header {
  width: 1500px;
  margin: auto;
  display: flex;
  color: #c0192b;
  height: 200px;
  align-items: flex-end;
  margin-bottom: 25px;
  .img {
    width: 835px;
    height: 106px;
  }
  .right {
    margin-left: 200px;
    line-height: 80px;
    margin-top: 40px;
    margin-left: 150px;
    .headerSkip {
      font-size: 15px;
      text-align: right;
      font-weight: 500;
      cursor: pointer;
      img{
        width: 150px;
        height: 150px;
      }
      .after {
        margin-right: 20px;
      }
      .after::after {
        content: "";
        display: inline-block;
        height: 13px;
        width: 2px;
        border-radius: 1px;
        background-color: #c0192b;
        position: relative;
        left: 10px;
        top: 1px;
      }

      a {
        color: #c0192b;
        margin-right: 10px;
      }
    }

    .headerSearch {
      display: flex;
      margin-top: 10px;
      .searchIpt {
        width: 307px;
        height: 34px;
        border: none;
        font-size: 19px;
        border: 1px solid #c0192b;
        padding-left: 12px;
      }
      .searchIpt:focus {
        outline: 1px solid #c0192b;
        border-radius: 4px;
      }
      .searchIpt::placeholder {
        font-size: 18px;
        color: #8d8d95;
      }
      .serachImg {
        cursor: pointer;
        height: 36px;
        width: 47px;
        background-color: #c0192b;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 20.89px;
          height: 20.89px;
        }
      }
    }
  }
}

.navigation {
  background-color: #c0192b;
  .navigation-box {
    width: 1500px;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    li:last-child {
      border-right: 1px solid #ffffff;
    }
    li {
      position: relative;
      width: 200px;
      height: 55px;
      padding: 5px 0;
      box-sizing: border-box;
      border-left: 1px solid #ffffff;
      cursor: pointer;
      div {
        margin: auto;
        height: 100%;
        width: 130px;
        display: flex;
        // justify-content: center;
        align-items: center;

        img {
          width: 28px;
          height: 28px;
          margin: 0 10px;
        }
        nav {
          font-size: 19px;
          color: #ffffff;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
        }
      }

      .hover-box {
        position: absolute;
        top: 55px;
        left: 0%;
        z-index: 99;
        width: 180px;
        box-sizing: border-box;
        height: auto;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);

        background-color: #fff;
        .box-container {
          display: flex;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          flex-direction: column;
          align-items: center;
          .box-items {
            box-sizing: border-box;
            height: 50px;
            width: 100% !important;
            display: flex;
            align-items: center;
            padding: 8px 0;
            color: #c0192b;
            justify-content: center;
          }
          .box-items:hover {
            color: #fff;
            background-color: #c0192b;
            opacity: 0.6;
          }
          .box-items-act {
            box-sizing: border-box;
            height: 50px;
            width: 100% !important;
            display: flex;
            align-items: center;
            padding: 8px 0;
            color: #fff;
            background-color: #c0192b;
            justify-content: center;
          }
        }
      }
      .active {
        border-bottom: 5px solid #ffffff;
      }
      .act-class {
        margin: auto;
        height: 45px;
        width: 130px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 5px solid #ffffff;
        img {
          width: 28px;
          height: 28px;
        }
        nav {
          font-size: 19px;
          color: #ffffff;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
        }
      }
    }
  }
}
.breadcrumb-title {
  margin: 30px 0;
  display: flex;
  color: #898989;
  align-items: center;
}

</style>
