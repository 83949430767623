import { request } from '@/request/index'

const URLS = {
  reqHomeBannerList: '/api/banner/index/list',
  reqHomeColumnTitle: '/api/nav/menu/latest?ids=',
  reqMenuList: '/api/nav/menu/list?pid=',
  reqHomeVideoList: '/api/news/video/first?',
  /*合作伙伴*/
  reqHomeCooperate: '/api/parntner/cooperate/first?',
  /* 文章推荐列表 */
  reqHomeRecommendList: '/api/news/article/first?',
  /* 专项基金 */
  reqWelfareRecommend: '/api/project/welfare/recommend?',
  /* 公式公告 */
  reqNoticeBulletin: '/api/notice/bulletin/first?',
  /* 请求捐赠信息  金额 捐款人数 次数 */
  reqDonateStatistics: '/api/donate/check/stat',
  /* 请求捐赠故事 慈善文化列表 */
  reqDonateStory: '/api/idol/brand/first?',
  /* 首页焦点标题 */
  reqHomeTitle: '/api/news/article/focus',
  /* 首页服务阵地 */
  reqHomeServe: '/api/link/focus?',
  /* 服务阵地 */
  reqServeList: '/api/link/list?'
}



/* 请求导航栏数据 */

export const reqMenuList = (id) => {
  return request.get(`${URLS.reqMenuList}${id}`)
}


/* 请求首页轮播图数据列表  */
export const reqHomeBannerList = () => {
  return request.get(URLS.reqHomeBannerList)
}


/*  请求栏目信息列表标题 */
export const reqHomeColumnTitle = (nav_id) => {
  return request.get(`${URLS.reqHomeColumnTitle}${nav_id}`)
}


/*首页视频*/
export const reqHomeVideoList = (nav_id, limit) => {
  return request.get(`${URLS.reqHomeVideoList}&nav_id=${nav_id}&limit=${limit}`)
}

/*首页合作*/
export const reqHomeCooperate = (nav_id, limit) => {
  return request.get(`${URLS.reqHomeCooperate}&nav_id=${nav_id}&limit=${limit}`)
}


/* 首页文章推荐列表 */

export const reqHomeRecommendList = (nav_id, limit) => {
  return request.get(`${URLS.reqHomeRecommendList}nav_id=${nav_id}&limit=${limit}`)
}


/* 首页专项基金 */

export const reqWelfareRecommend = (nav_id, limit) => {
  return request.get(`${URLS.reqWelfareRecommend}nav_id=${nav_id}&limit=${limit}`)
}

/* 公示公告 列表 */

export const reqNoticeBulletin = (nav_id, limit) => {
  return request.get(`${URLS.reqNoticeBulletin}nav_id=${nav_id}&limit=${limit}`)
}

/* 捐赠信息 金额 人数 */

export const reqDonateStatistics = () => {
  return request.get(URLS.reqDonateStatistics)
}

/* 请求捐赠故事 慈善文化 */

export const reqDonateStory = (id, limit) => {
  return request.get(`${URLS.reqDonateStory}nav_id=${id}&limit=${limit}`)
}

/* 首页焦点标题 */
export const reqHomeTitle = () => {
  return request.get(URLS.reqHomeTitle)
}

/* 首页服务阵地 */
export const reqHomeServe = (limit) => {
  return request.get(`${URLS.reqHomeServe}&limit=${limit}`)
}


export const reqServeList = (id,limit) => {
  return request.get(`${URLS.reqServeList}nav_id=${id}&limit=${limit}`)
}
