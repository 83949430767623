import { request } from "@/request";

const URLS = {
      /* 网站底部配置 */
  reqSystemConfig:"/api/system/config"
}

/* w网站配置 */
export const reqSystemConfig = ()=>{
    return request.get(URLS.reqSystemConfig)
  }